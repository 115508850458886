import React from "react";
import '@fortawesome/fontawesome-svg-core/styles.css'
import HasAccess from "../../../components/organisms/hasAccess";

export default class IndexPage extends React.Component {

  render() {
    return (
      <div>
       <HasAccess></HasAccess>
      </div>
    );
  }
}
